import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className="contentholder">
    <p>At Mugs Games (“we”, “us”, or “our”) we are committed to protect and respect your privacy in compliance with EU- General Data Protection Regulation (GDPR). This Privacy Policy describes the types of personal information we collect about our customers, how we process the information, with whom we share it, and the choices available to our customers regarding our use of the information. We also describe the measures we take to protect the security of the information and how our customers can contact us about our privacy practices. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting mugs-games.com you are accepting and consenting to the practices described in this policy.</p>

<p><h2>1. INFORMATION WE COLLECT</h2>
1. We may obtain personal information about you from various sources. We may collect this information when you provide it at one of stores, our social media platforms or at one of our events. When you visit this site, our social media platforms, or social networking or mobile applications, we may also collect certain information by automated means, using technologies such as cookies, web server logs and web beacons.
2. You may also choose to provide personal information to us in a number of ways, such as when you participate in an offer or promotion, or when you make a purchase on our site, at our counters or in our stores.
3. The types of personal information we may collect from you and which you may provide to us includes: contact information (such as name, postal address, email address, and mobile or other phone number); age and date of birth; gender; username and password; payment information (such as your payment card number, expiry date, delivery address, and billing address); your online and in-store purchase history; product preferences; contact information for friends or other people you would like us to contact; and content you provide (such as reviews and comments).</p>

<p><h2>2. HOW WE USE THE INFORMATION</h2>
1. We may use the information you provide to send you promotional materials or other communications; provide services to you; process your payment; create and manage your online account; respond to your enquiries; communicate with you about, and administer your participation in, special events, prize promotions, programmes, surveys and other offers; evaluate and improve our business (including developing new products and services; enhancing and improving our services; managing our communications; analysing our products; performing data analytics; and performing accounting, auditing and other internal functions); and comply with applicable legal requirements, relevant industry standards and our policies.
2. We also may use the information in other ways for which we provide specific notice at the time of collection.</p>

<p><h2>3. INFORMATION WE COLLECT BY AUTOMATED MEANS</h2>
When you visit this site or our online advertisements, we collect certain information by automated means, using technologies such as cookies, web server logs and web beacons. Information may include (i) technical information, such as the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone settings, language settings, domain, operating system, platform or other system settings, and (ii) information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site, products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information, and methods used to browse away from the page and any phone number used to call our customer service number.</p>

<p><h2>4. TECHNOLOGIES WE USE</h2>
1. Cookies, Web Server Logs and Web Beacons. Cookies are small text files that websites send to your computer or other Internet-connected device to uniquely identify your browser or to store information or settings in your browser. Your browser may tell you how to be notified when you receive certain types of cookies and how to restrict or disable certain cookies. Please note, however, that without cookies you may not be able to use all of the features of our website. By continuing to browse the site, you are agreeing to the use of cookies.
2. In conjunction with obtaining information through cookies, our web servers may log details such as your operating system type, browser type, domain, and other system settings, as well as the language your system uses and the country and time zone in which your device is located. The web server logs also may record information such as the address of the web page that linked you to our site and the IP address of the device you use to connect to the Internet.
3. To control which web servers collect this information, we may place tags on our web pages called “web beacons”. These are computer instructions that link web pages to particular web servers and their cookies.
4. Third Party Web Analytics Services. We may use third party web analytics services on this site, our social media platforms, such as Google Analytics. The service providers that administer these services use technologies such as cookies, web server logs and web beacons (over which we have no control) to help us analyse how visitors use the site. The information collected through these means (including IP address) is disclosed to these service providers, who use the information to evaluate use of the website. To disable the Google Analytics cookie, some browsers indicate when a cookie is being sent and allow you to decline cookies on a case-by-case basis.
5. Targeted Advertising . We also may contract with third-party advertising networks that collect IP addresses and other information through the use of cookies, web server logs and web beacons on our websites and emails; on third-party websites and emails; and on our advertising placed on third-party websites (over which we have no control). They use this information to provide advertisements about products and services tailored to your interests (including for companies not affiliated with us). You may see these advertisements on our websites and other websites. This process also helps us manage and track the effectiveness of our marketing efforts.</p>

<p><h2>5. HOW WE USE THE INFORMATION COLLECTED BY AUTOMATED MEANS</h2>
We may use the information collected through automated means on this site to deliver personalised content, for market research, data analytics and system administration purposes, such as to determine whether you’ve visited us before or are new to the site, and for compliance with our legal obligations, policies and procedures, including compliance with relevant industry standards and the enforcement of our Terms and Conditions. We also may use the information in other ways for which specific notice is provided at the time of collection.</p>

<p><h2>6. INFORMATION WE SHARE</h2>
1. We do not rent lists, sell or otherwise disclose personal information we collect about you, except as described here. We may share your personal information with:
1. Third party service providers who perform services on our behalf based on our instructions. We do not authorise these service providers to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements. Examples of these service providers include entities that: process credit card payments; fulfil orders; provide web hosting and marketing services; and provide data cleansing and analytics services (should you wish to opt out of these third party sharing arrangements in the future, you simply need to opt out of e-mail and SMS communications from us); and
2. Other third parties with your consent (e.g., some of our Facebook applications may share information collected through those applications with your Facebook friends or other Facebook users).
2. In addition, we may disclose information about you (i) if we are required to do so by law or legal process, (ii) to law enforcement authorities or other government officials, or (iii) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, or in connection with an investigation of suspected or actual fraudulent or illegal activity.</p>

<p>3. We may process your personal information for our legitimate business interests. ‘Legitimate Interests’ means the interests of our company in conducting and managing our business to enable us to give you the best service/products and the best and most secure experience. It can and does also apply to processing which is in your interests too. Processing for our legitimate interests may include processing for the purposes of (i) fraud prevention and compliance; (ii) certain direct marketing and promotional activities; (iii) the provision and operation of referral marketing programmes; (iv) network and information systems security; (v) data analytics; (vi) enhancing, modifying or improving our service; (vii) identifying usage trends; or (viii) determining the effectiveness of promotional campaigns or advertising. In connection with the above activities, we may share your personal information with trusted suppliers who assist us in our data processing activities. For a list of our current suppliers with whom we share data and what they do with that data, please click here. When we process your personal information for our legitimate interests, we make sure to consider and balance any potential impact on you (both positive and negative), and your rights under data protection laws. Our legitimate business interests do not automatically override your interests – we will not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted by law). You have the right to object to this processing if you wish and if you wish to do so please contact hello@mugs-games.com.</p>

<p><h2>7. YOUR RIGHTS AND CHOICES</h2>
We offer you certain choices in connection with the personal information we collect from you, such as how we use the information and how we communicate with you. To update your preferences, ask us to remove your information from our mailing lists or submit a request, please contact us on hello@mugs-games.com.</p>

<p><h2>8. EMAIL OPT-OUT</h2>
You can at any time tell us not to send you marketing communications by email by clicking on the unsubscribe link within the marketing emails you receive from us. You also may opt out of receiving marketing emails by contacting us on hello@mugs-games.com.</p>

<p><h2>9. POSTAL MAIL OPT-OUT</h2>
You can ask us to stop sending you marketing communications by postal mail by following the instructions that may be included in a particular promotion. You also can request that we refrain from sending you promotional postal mail by by contacting us on hello@mugs-games.com.</p>

<p><h2>10. WITHDRAWING CONSENT</h2>
You may withdraw any consent you previously provided to us, or object at any time on legitimate grounds, to the processing of your personal information. We will consider your request and, as required by applicable law, apply your preferences going forward, within a reasonable amount of time. Even where you withdraw your consent, we may still process your personal data for limited purposes, for example, to give effect to your request, or to safeguard our business. In some circumstances, withdrawing your consent to our use or disclosure of your personal information will mean that you cannot take advantage of some of our products or services.</p>

<p><h2>11. REVIEWING, UPDATING AND MODIFYING PERSONAL INFORMATION</h2>
Subject to applicable law, you may have the right to request access to and receive details about the personal information we maintain about you, update and correct inaccuracies in your personal data, and have the information blocked or deleted, as appropriate. The right to access personal information may be limited in some circumstances by local law requirements. You may request to review, change or delete your personal information by sending an email to hello@mugs-games.com.</p>

<p><h2>12. HOW WE PROTECT PERSONAL INFORMATION</h2>
We maintain appropriate administrative, technical and physical safeguards designed to protect the personal information you provide against accidental, unlawful or unauthorised destruction, loss, alteration, access, disclosure or use.</p>

<p><h2>13. COOKIES</h2>
Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit www.aboutcookies.org or www.allaboutcookies.org.</p>

<p><h2>14. UPDATES TO OUR PRIVACY POLICY</h2>
This Privacy Policy may be updated periodically and without prior notice to you to reflect changes in our personal information practices. We will post a prominent notice on our website to notify you of any significant changes to our Privacy Policy and indicate at the top of the Policy when it was most recently updated.</p>

<p><h2>15. HOW TO CONTACT US AND ACCESS TO INFORMATION</h2>
If you have any questions or comments about this Privacy Policy, or if you would like us to update information we have about you or your preferences, please contact us by email hello@mugs-games.com. You can also write to us at MUGS GAMES LTD, Hackney Bridge, Units 1-28,ECHO BUILDING,London E15 2SJ.</p>

<p><h2>16. NON-WAIVER</h2>
No failure to exercise and no delay on our part in exercising any of our rights, remedies, powers or privileges under these Terms & Conditions (of which this Privacy & Cookie Policy forms part) and no course of dealing between us shall be construed or operate as a waiver, nor shall any single or partial exercise of any right, remedy, power or privilege preclude any other or further exercise thereof or the exercise of any other right, remedy, power or privilege on our part.</p>

<p><h2>17. LAW, JURISDICTION AND LANGUAGE</h2>
This Site, any content contained herein and any contracts entered into as a result of usage of this Site are governed by English law. The parties to any such contract agree to submit to the exclusive jurisdiction of the courts of England and Wales. All contracts are concluded in English.</p>
    </div>
  </Layout>
)

export default IndexPage
